import { Container, List } from "reactstrap";
import {
  ProjectLabelInner,
  ProjectListCard,
  ProjectListInner,
  ProjectListLabel,
  ProjectListTitle,
  ProjectTeamCards,
  ProjectTeamInner,
  ProjectTeamMain,
  ServiceHeader,
  ServiceRight,
  ServicesLeft,
} from "../../StyledComponents/LandingStyled";
import { ProjectTeamArray } from "../../DummyData";
import { motion } from "framer-motion";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Image from "next/image";

export default function ProjectTeam() {
  const customEase = [0, 0, 0.5, 1]; // cubic-bezier parameters
  const formattedFaqs = ProjectTeamArray.map((d) => ({
    ...d,
    heading: d.title.replace(/\n/g, "<br />"),
    inner: d.inner.replace(/\n/g, "<br />"),
  }));
  return (
    <Container className="p-b-section">
      <ProjectTeamMain>
        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
          <ServiceHeader>
            <ServicesLeft className="projectTeam !mb-0">
              <ProjectTeamInner className="ps-0">
                Our Commitment to Consistent <br  /> Quality in Every Project
              </ProjectTeamInner>
            </ServicesLeft>
            <ServiceRight className="projectTeam">
              Understand our process for delivering the best results, every
              time.
            </ServiceRight>
          </ServiceHeader>
        </AnimationOnScroll>
        <motion.div
          initial={{ opacity: 0, y: 25 }}
          transition={{
            duration: 1,
            ease: customEase,
            delay: 0.0,
          }}
          whileInView={{ opacity: 1, y: 0 }}
        >
          <ProjectTeamCards className="!pb-0">
            {ProjectTeamArray?.map((d) => (
              <ProjectListCard className="team-dark" key={d.id}>
                <ProjectListTitle
                  dangerouslySetInnerHTML={{ __html: d.title }}
                ></ProjectListTitle>
                <ProjectListInner
                  className="hone-We-are-text"
                  dangerouslySetInnerHTML={{ __html: d.inner }}
                ></ProjectListInner>
                {d.inner2 && (
                  <ProjectListInner className="hone-We-are-text">
                    {d.inner2}
                  </ProjectListInner>
                )}
                <List>
                  {d.list &&
                    d.list.map((j) => (
                      <li key={j.id}>
                        <Image
                          src="/svgs/Round-with-check-Pink.svg"
                          alt="Right Arrow"
                          width={21}
                          height={22}
                          loading="lazy"
                        />
                        {j.name}
                      </li>
                    ))}
                </List>
                <ProjectListLabel>
                  <ProjectLabelInner>{d.label}</ProjectLabelInner>
                </ProjectListLabel>
              </ProjectListCard>
            ))}
          </ProjectTeamCards>
        </motion.div>
      </ProjectTeamMain>
    </Container>
  );
}
